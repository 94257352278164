import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitter = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_twitter_svg__a)">
        <path
          d="M20 16.667c0 2.48-.857 3.333-3.333 3.333H3.333C.857 20 0 19.143 0 16.667V3.333C0 .857.857 0 3.333 0h13.334C19.143 0 20 .857 20 3.333v13.334Z"
          fill="#34C4F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.132 12.543c-1.183-.043-1.921-.89-2.117-1.578.328.062.653.05.977-.031.008 0 .015-.008.031-.016-.64-.148-1.133-.492-1.477-1.043a2.218 2.218 0 0 1-.332-1.21c.32.171.653.265 1.012.276-.48-.347-.805-.8-.937-1.378a2.252 2.252 0 0 1 .242-1.649C6.76 7.364 8.32 8.16 10.222 8.29c-.011-.09-.027-.168-.035-.25a2.215 2.215 0 0 1 .332-1.488c.363-.567.879-.922 1.543-1.028.766-.125 1.434.094 1.992.633.04.035.067.047.121.035.47-.101.907-.27 1.325-.507.011-.004.02-.012.03-.016h.016c-.18.527-.507.938-.972 1.238.441-.047.86-.164 1.265-.34.004.004.008.008.008.012-.086.113-.172.23-.261.34-.243.297-.52.559-.829.785-.027.02-.039.04-.039.07.012.336 0 .676-.043 1.012a6.766 6.766 0 0 1-.586 2.024 6.566 6.566 0 0 1-1.23 1.793 6.1 6.1 0 0 1-3.125 1.742c-.418.09-.84.136-1.266.152a6.435 6.435 0 0 1-3.64-.976c-.016-.008-.028-.02-.055-.036a4.593 4.593 0 0 0 2.273-.312c.391-.156.75-.363 1.086-.629Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgTwitter;
