import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktok = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_tiktok_svg__a)">
        <path
          d="M26.68 0H5.32A5.32 5.32 0 0 0 0 5.32v21.36A5.32 5.32 0 0 0 5.32 32h21.36A5.32 5.32 0 0 0 32 26.68V5.32A5.32 5.32 0 0 0 26.68 0Z"
          fill="#000"
        />
        <path
          d="M24.337 13.87v-3.567c-4.663-.197-4.813-4.434-4.813-4.832v-.03h-3.603v13.892a2.853 2.853 0 1 1-2.023-2.732v-3.678a6.464 6.464 0 1 0 5.631 6.41c0-.09 0-.18-.006-.27v-6.84c1.66 1.518 4.814 1.647 4.814 1.647Z"
          fill="#00F6EF"
        />
        <path
          d="M25.398 14.75v-3.569c-4.663-.197-4.812-4.434-4.812-4.832v-.03h-3.604v13.892a2.854 2.854 0 1 1-2.023-2.731V13.8a6.52 6.52 0 0 0-.834-.05 6.463 6.463 0 1 0 6.466 6.461c0-.09 0-.18-.007-.27v-6.84c1.66 1.518 4.814 1.649 4.814 1.649Z"
          fill="#fff"
        />
        <path
          d="M19.614 6.32c.174.946.681 2.425 2.16 3.29-1.13-1.316-1.187-3.016-1.187-3.26v-.03h-.973ZM25.398 14.75v-3.569a6.66 6.66 0 0 1-1.062-.125v2.81s-3.155-.13-4.813-1.648v6.839a6.465 6.465 0 0 1-10.039 5.651 6.463 6.463 0 0 0 11.107-4.497c0-.09 0-.18-.007-.27v-6.84c1.66 1.518 4.814 1.649 4.814 1.649Z"
          fill="#FF004F"
        />
        <path
          d="M13.898 16.596a2.856 2.856 0 0 0-2.074 5.303 2.856 2.856 0 0 1 3.135-4.419V13.8a6.538 6.538 0 0 0-.834-.05c-.077 0-.152 0-.229.004l.002 2.842Z"
          fill="#FF004F"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgTiktok;
