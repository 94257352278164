import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgLinkedin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_linkedin_svg__a)">
        <path
          d="M32 26.667C32 30.635 30.635 32 26.667 32H5.333C1.371 32 0 31.969 0 28V5.333C0 1.371 1.37 0 5.333 0h21.334C30.635 0 32 1.37 32 5.333v21.334Z"
          fill="#1387C8"
        />
        <path
          d="M8.894 13.306h3.337v10.725H8.894V13.306Zm1.668-5.337c1.07 0 1.932.869 1.932 1.931a1.938 1.938 0 0 1-1.931 1.938A1.94 1.94 0 0 1 8.624 9.9a1.942 1.942 0 0 1 1.938-1.931ZM14.324 13.306h3.2v1.47h.044c.444-.845 1.531-1.732 3.156-1.732 3.375 0 4 2.219 4 5.106v5.881h-3.331v-5.218c0-1.244-.025-2.844-1.731-2.844-1.738 0-2 1.356-2 2.75v5.306H14.33V13.306h-.007Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgLinkedin;
