import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktok = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_tiktok_svg__a)">
        <path
          d="M16.675 0H3.325A3.325 3.325 0 0 0 0 3.325v13.35A3.325 3.325 0 0 0 3.325 20h13.35A3.325 3.325 0 0 0 20 16.675V3.325A3.325 3.325 0 0 0 16.675 0Z"
          fill="#000"
        />
        <path
          d="M15.21 8.669v-2.23c-2.914-.123-3.007-2.77-3.007-3.02v-.018H9.95v8.682a1.784 1.784 0 1 1-1.264-1.707v-2.3a4.04 4.04 0 1 0 3.52 4.007c0-.056 0-.113-.004-.169V7.64c1.037.949 3.009 1.03 3.009 1.03Z"
          fill="#00F6EF"
        />
        <path
          d="M15.874 9.219v-2.23c-2.915-.124-3.008-2.772-3.008-3.02v-.02h-2.252v8.683a1.784 1.784 0 1 1-1.264-1.707v-2.3a4.082 4.082 0 0 0-2.766.65 4.04 4.04 0 1 0 6.285 3.357c0-.057 0-.113-.004-.169V8.188c1.037.949 3.009 1.03 3.009 1.03Z"
          fill="#fff"
        />
        <path
          d="M12.259 3.95c.108.591.425 1.516 1.35 2.057-.707-.823-.742-1.886-.742-2.039V3.95h-.608ZM15.874 9.219v-2.23a4.158 4.158 0 0 1-.664-.079v1.756s-1.972-.08-3.008-1.03v4.275a4.04 4.04 0 0 1-6.274 3.532 4.04 4.04 0 0 0 6.941-2.811c0-.057 0-.113-.004-.169V8.188c1.037.949 3.009 1.03 3.009 1.03Z"
          fill="#FF004F"
        />
        <path
          d="M8.686 10.372a1.784 1.784 0 0 0-1.296 3.315 1.784 1.784 0 0 1 1.96-2.762v-2.3a4.086 4.086 0 0 0-.522-.031c-.048 0-.095 0-.143.003l.001 1.775Z"
          fill="#FF004F"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgTiktok;
