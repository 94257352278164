import * as React from 'react';
import { SVGProps } from 'react';
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.54 5.19a2.502 2.502 0 0 0-1.76-1.771C16.225 3 10 3 10 3s-6.226 0-7.78.419c-.856.23-1.53.91-1.76 1.772-.416 1.562-.416 4.823-.416 4.823s0 3.261.417 4.824a2.465 2.465 0 0 0 1.76 1.743C3.774 17 10 17 10 17s6.226 0 7.78-.419a2.465 2.465 0 0 0 1.76-1.743c.416-1.563.416-4.824.416-4.824s0-3.26-.416-4.824Z"
      fill="red"
    />
    <path d="M7.964 12.975V7.054l5.204 2.96-5.204 2.96Z" fill="#fff" />
  </svg>
);
export default SvgYoutube;
