import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgPinterest = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_pinterest_svg__a)">
        <path
          d="M32 26.667C32 30.635 30.63 32 26.667 32H5.333C1.371 32 0 30.63 0 26.667V5.333C0 1.371 1.474 0 5.436 0h21.23C30.63 0 32 1.37 32 5.333v21.334Z"
          fill="#EF3F3B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9 18.637c-.013.05-.032.094-.038.131-.637 2.494-.706 3.044-1.363 4.207a12.71 12.71 0 0 1-1.05 1.568c-.043.057-.087.132-.175.113-.093-.019-.1-.106-.112-.181a13.28 13.28 0 0 1-.138-2.282c.032-.993.157-1.337 1.438-6.737a.35.35 0 0 0-.031-.225c-.306-.831-.369-1.669-.1-2.525.581-1.844 2.668-1.981 3.037-.463.225.938-.368 2.17-.825 3.988-.381 1.5 1.388 2.569 2.9 1.475 1.394-1.013 1.931-3.431 1.831-5.144-.2-3.419-3.95-4.162-6.33-3.056-2.732 1.262-3.35 4.644-2.12 6.187.157.194.276.32.226.52-.082.312-.15.618-.238.93-.063.232-.256.313-.487.22a2.75 2.75 0 0 1-1.138-.857c-1.044-1.294-1.344-3.856.038-6.025 1.53-2.406 4.38-3.375 6.987-3.081 3.106.356 5.075 2.475 5.444 4.887.169 1.1.044 3.806-1.494 5.719-1.775 2.2-4.644 2.344-5.969.994-.112-.12-.194-.238-.293-.363Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPinterest;
