import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgPinterest = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_pinterest_svg__a)">
        <path
          d="M20 16.667c0 2.48-.857 3.333-3.333 3.333H3.333C.857 20 0 19.143 0 16.667V3.333C0 .857.921 0 3.398 0h13.269C19.143 0 20 .857 20 3.333v13.334Z"
          fill="#EF3F3B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.312 11.648c-.008.032-.02.059-.023.082-.399 1.559-.442 1.903-.852 2.63a7.945 7.945 0 0 1-.656.98c-.027.035-.055.082-.11.07-.058-.012-.062-.066-.07-.113a8.298 8.298 0 0 1-.086-1.426c.02-.621.098-.836.899-4.211a.22.22 0 0 0-.02-.14c-.191-.52-.23-1.044-.062-1.579C8.695 6.79 10 6.703 10.23 7.652c.14.586-.23 1.356-.515 2.492-.239.938.867 1.606 1.812.922.871-.633 1.207-2.144 1.145-3.215-.125-2.136-2.47-2.601-3.957-1.91-1.707.79-2.094 2.903-1.325 3.867.098.122.172.2.141.325-.05.195-.094.386-.148.582-.04.144-.16.195-.305.136a1.719 1.719 0 0 1-.711-.535c-.652-.808-.84-2.41.023-3.765.957-1.504 2.739-2.11 4.368-1.926 1.94.222 3.171 1.547 3.402 3.055.105.687.027 2.378-.934 3.574-1.11 1.375-2.902 1.465-3.73.62-.07-.073-.121-.148-.184-.226Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPinterest;
