import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitter = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_twitter_svg__a)">
        <path
          d="M32 26.667C32 30.635 30.63 32 26.667 32H5.333C1.371 32 0 30.63 0 26.667V5.333C0 1.371 1.37 0 5.333 0h21.334C30.629 0 32 1.37 32 5.333v21.334Z"
          fill="#34C4F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.012 20.069c-1.894-.069-3.075-1.425-3.388-2.525.525.1 1.044.081 1.563-.05.012 0 .025-.013.05-.025-1.025-.238-1.813-.788-2.363-1.669a3.549 3.549 0 0 1-.531-1.937 3.612 3.612 0 0 0 1.619.443c-.769-.556-1.288-1.28-1.5-2.206-.213-.925-.075-1.806.387-2.637 1.969 2.318 4.463 3.593 7.506 3.8-.018-.144-.043-.27-.056-.4-.1-.857.069-1.657.532-2.382.58-.906 1.406-1.475 2.468-1.643 1.225-.2 2.294.15 3.188 1.012.062.056.106.075.194.056a7.4 7.4 0 0 0 2.118-.812c.019-.006.032-.019.05-.025h.025a3.74 3.74 0 0 1-1.556 1.981 6.896 6.896 0 0 0 2.025-.544c.006.007.012.013.012.02-.137.18-.274.368-.418.543a7.212 7.212 0 0 1-1.325 1.256c-.044.031-.063.063-.063.113.019.537 0 1.08-.069 1.618a10.822 10.822 0 0 1-.937 3.238 10.51 10.51 0 0 1-1.969 2.869 9.76 9.76 0 0 1-5 2.787c-.668.144-1.344.219-2.025.244-2.1.062-4.037-.463-5.825-1.563-.025-.012-.043-.03-.087-.056a7.35 7.35 0 0 0 3.637-.5c.625-.25 1.2-.581 1.738-1.006Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgTwitter;
