import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebook = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_facebook_svg__a)">
        <path
          d="M32 26.667C32 30.635 30.63 32 26.667 32H5.333C1.371 32 0 30.63 0 26.667V5.333C0 1.371 1.37 0 5.333 0h21.334C30.629 0 32 1.37 32 5.333v21.334Z"
          fill="#4F76BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.88 9.59c.537-.02 1.075-.007 1.612-.007h.219V6.789c-.288-.031-.588-.069-.888-.081-.55-.025-1.1-.05-1.656-.038-.844.02-1.637.225-2.331.72-.8.574-1.238 1.38-1.4 2.33-.069.394-.088.8-.1 1.2-.013.625 0 1.25 0 1.882v.237h-2.669v3.119h2.656v7.837h3.244v-7.83h2.65c.138-1.038.269-2.063.406-3.132h-.593c-.757.006-2.482 0-2.482 0s.007-1.544.025-2.219c.025-.919.575-1.2 1.307-1.225Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFacebook;
