import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgInstagram20 = function (props: SVGProps<SVGSVGElement>) {
  const _id2 = useUniqueInlineId();
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${_id2})`}>
        <path
          d="M20 16.667C20 19.19 19.19 20 16.667 20H3.5C.977 20 0 19.023 0 16.5V3.333C0 .81.81 0 3.333 0h13.334C19.229 0 20 .77 20 3.333v13.334Z"
          fill={`url(#${_id})`}
        />
        <path
          d="M12.78 2.758H7.219c-2.465 0-4.465 2-4.465 4.465v5.558a4.46 4.46 0 0 0 4.465 4.461h5.559a4.46 4.46 0 0 0 4.46-4.46v-5.56a4.455 4.455 0 0 0-4.456-4.464Zm3.07 9.488a3.604 3.604 0 0 1-3.605 3.605H7.753a3.604 3.604 0 0 1-3.605-3.605V7.754a3.604 3.604 0 0 1 3.605-3.606h4.492a3.607 3.607 0 0 1 3.606 3.606v4.492Z"
          fill="#fff"
        />
        <path
          d="M10.195 6.21a3.817 3.817 0 1 0 0 7.632 3.817 3.817 0 0 0 0-7.631Zm0 6.11a2.296 2.296 0 0 1-2.297-2.297 2.298 2.298 0 0 1 4.594 0 2.3 2.3 0 0 1-2.297 2.297ZM14.715 6.152a.969.969 0 1 1-1.938 0 .97.97 0 1 1 1.938 0Z"
          fill="#fff"
        />
      </g>
      <defs>
        <radialGradient
          id={_id}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(26.503 0 0 26.5022 5.858 21.548)"
        >
          <stop offset={0.097} stopColor="#FFD87A" />
          <stop offset={0.143} stopColor="#FCCE78" />
          <stop offset={0.226} stopColor="#F5B471" />
          <stop offset={0.338} stopColor="#EB8D65" />
          <stop offset={0.449} stopColor="#E36058" />
          <stop offset={0.679} stopColor="#CD3694" />
          <stop offset={1} stopColor="#6668B0" />
        </radialGradient>
        <clipPath id={_id2}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgInstagram20;
