import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgInstagram32 = function (props: SVGProps<SVGSVGElement>) {
  const _id2 = useUniqueInlineId();
  const _id = useUniqueInlineId();
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${_id2})`}>
        <path
          d="M32 26.667C32 30.704 30.704 32 26.667 32H5.333C1.296 32 0 32.038 0 28V5.333C0 1.296 1.296 0 5.333 0h21.334C30.767 0 32 1.233 32 5.333v21.334Z"
          fill={`url(#${_id})`}
        />
        <path
          d="M20.449 4.412h-8.9a7.146 7.146 0 0 0-7.144 7.144v8.894a7.136 7.136 0 0 0 7.144 7.137h8.894a7.135 7.135 0 0 0 7.137-7.137v-8.894a7.127 7.127 0 0 0-7.131-7.144Zm4.912 15.182a5.767 5.767 0 0 1-5.768 5.768h-7.188a5.767 5.767 0 0 1-5.769-5.768v-7.188a5.767 5.767 0 0 1 5.77-5.769h7.187a5.77 5.77 0 0 1 5.768 5.77v7.187Z"
          fill="#fff"
        />
        <path
          d="M16.312 9.938a6.107 6.107 0 1 0 6.106 6.106 6.11 6.11 0 0 0-6.106-6.107Zm0 9.774a3.673 3.673 0 0 1-3.675-3.674 3.673 3.673 0 0 1 3.675-3.675 3.677 3.677 0 0 1 3.675 3.675 3.681 3.681 0 0 1-3.675 3.674ZM23.543 9.844a1.55 1.55 0 1 1-3.1 0c0-.857.694-1.557 1.55-1.557.857 0 1.55.694 1.55 1.557Z"
          fill="#fff"
        />
      </g>
      <defs>
        <radialGradient
          id={_id}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(42.4048 0 0 42.4036 9.373 34.477)"
        >
          <stop offset={0.097} stopColor="#FFD87A" />
          <stop offset={0.143} stopColor="#FCCE78" />
          <stop offset={0.226} stopColor="#F5B471" />
          <stop offset={0.338} stopColor="#EB8D65" />
          <stop offset={0.449} stopColor="#E36058" />
          <stop offset={0.679} stopColor="#CD3694" />
          <stop offset={1} stopColor="#6668B0" />
        </radialGradient>
        <clipPath id={_id2}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgInstagram32;
