import * as React from 'react';
import { SVGProps } from 'react';
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31.263 8.305a4.004 4.004 0 0 0-2.817-2.835C25.962 4.8 16 4.8 16 4.8s-9.962 0-12.447.67A4.003 4.003 0 0 0 .737 8.305C.07 10.805.07 16.023.07 16.023s0 5.217.666 7.717c.366 1.38 1.446 2.421 2.816 2.79C6.038 27.2 16 27.2 16 27.2s9.962 0 12.447-.67c1.37-.369 2.45-1.41 2.816-2.79.666-2.5.666-7.717.666-7.717s0-5.218-.666-7.718Z"
      fill="red"
    />
    <path d="M12.742 20.76v-9.474l8.326 4.737-8.326 4.736Z" fill="#fff" />
  </svg>
);
export default SvgYoutube;
