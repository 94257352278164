import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgLinkedin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_linkedin_svg__a)">
        <path
          d="M20 16.667c0 2.48-.853 3.333-3.333 3.333H3.5C1.023 20 0 18.98 0 16.5V3.333C0 .857.857 0 3.333 0h13.334C19.147 0 20 .857 20 3.333v13.334Z"
          fill="#1387C8"
        />
        <path
          d="M5.559 8.316h2.086v6.704H5.559V8.316ZM6.602 4.98A1.208 1.208 0 1 1 6.6 7.396a1.208 1.208 0 0 1 .002-2.416ZM8.953 8.317h2v.918h.027c.277-.528.957-1.083 1.973-1.083 2.11 0 2.5 1.387 2.5 3.192v3.676H13.37v-3.262c0-.777-.016-1.777-1.082-1.777-1.086 0-1.25.847-1.25 1.718v3.317H8.957v-6.7h-.004Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgLinkedin;
