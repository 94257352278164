import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebook = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#_facebook_svg__a)">
        <path
          d="M20 16.667c0 2.48-.857 3.333-3.333 3.333H3.333C.857 20 0 19.143 0 16.667V3.333C0 .857.857 0 3.333 0h13.334C19.143 0 20 .857 20 3.333v13.334Z"
          fill="#4F76BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.175 5.993c.336-.012.672-.004 1.008-.004h.136V4.243c-.18-.02-.367-.043-.554-.05-.344-.016-.688-.032-1.036-.024-.527.012-1.023.14-1.457.449-.5.36-.773.863-.875 1.457-.043.246-.054.5-.062.75-.008.39 0 .781 0 1.176v.148H6.667v1.95h1.66v4.898h2.027v-4.895h1.657c.086-.648.168-1.289.254-1.957h-.371c-.473.004-1.551 0-1.551 0s.004-.964.015-1.386c.016-.575.36-.75.817-.766Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={_id}>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFacebook;
